// extracted by mini-css-extract-plugin
export var alternate_direction = "styles-module--alternate_direction--016ef";
export var blackish = "#121117";
export var border = "styles-module--border--08cf4";
export var colors = "\"../../../assets/css/_colors.css\"";
export var deepOrange = "#FF8A0F";
export var deepYellow = "#FFCC23";
export var description = "styles-module--description--dd1c8";
export var dotted_hr = "styles-module--dotted_hr--7b92b";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var gatsby_image = "styles-module--gatsby_image--96980";
export var gtWalsheim = "'gt-walsheim'";
export var headline = "styles-module--headline--103c5";
export var image_container = "styles-module--image_container--7f8c8";
export var lightBg = "#FFF8E8";
export var list = "styles-module--list--c293b";
export var list_container = "styles-module--list_container--9b0d0";
export var list_description = "styles-module--list_description--41020";
export var list_item = "styles-module--list_item--9aa04";
export var list_item_headline = "styles-module--list_item_headline--a6fd5";
export var open = "styles-module--open--9a041";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var yellowBtn = "#FFE07B";