import React from "react"
import { graphql } from "gatsby"
import { Seo } from "../components/seo"
import Layout from "../components/layout"
import GenericBanner from "../components/genericBanner"
import WhatWeTreat from "../components/whatWeTreat"
import OurApproach from "../components/ourApproach"
import ServiceOfferings from "../components/serviceOfferings"
import Cta from "../components/cta"

export default function ServicesPage({ data: { page }}) {
    return(
        <Layout>
          {page.banner.map((block, index) =>
              <GenericBanner key={index} banner={block} title={page.title} marginTop={60} marginBottom={120} mobileMarginBottom={60}/>
          )}
          <WhatWeTreat 
            headline={page.headline} 
            button={page.button}
            treatments={page.treatments}  
          />
          <OurApproach 
            headline={page.ourApproachHeadline} 
            button={page.ourApproachButton}
            cards={page.approach}
          />
          <ServiceOfferings 
            headline={page.offeringsHeadline}
            button={page.offeringsButton}
            offerings={page.offerings}
          />
          {page.cta.map((block, index) =>
            <Cta 
              key={index} 
              data={block} 
              type={`${block.model.apiKey === "shapes_cta" ? "shapes" : "default"}`}
            />
          )}
        </Layout>
    )
}

export const data = graphql`
  query ServicesQuery {
    page: datoCmsServicesPage {
      title
      banner {
        backgroundImage {
          alt 
          data: gatsbyImageData
          url
        }
        mobileBackgroundImage {
          alt 
          data: gatsbyImageData
          url
        }
      }
      headline
      button {
        buttonText
        buttonUrl
        newTab
      }
      treatments {
        title
        description
      }
      ourApproachHeadline
      ourApproachButton {
        buttonText
        buttonUrl
        newTab
      }
      approach {
        title
        iconAnimation {
          url
        }
        description
      }
      offeringsHeadline
      offeringsButton {
        buttonText
        buttonUrl
        newTab
      }
      offerings {
        title
        description
        expandableList {
          headline
          body
        }
        image {
          data: gatsbyImageData (
            layout: FULL_WIDTH,
            width: 672,
            height: 653
          )
          focalPoint {
            x
            y
          }
          url
          alt
        }
      }
      cta {
        ... on DatoCmsShapesCta {
          model {
            apiKey
          }
          headline
          body
          buttonText 
          buttonUrl
          newTab
          shapeType
        }
      }
      seo {
        title
        description
        image {
          url
        }
      }
    }
  }
`

export const Head = ({ data }) => {
    return (
      <Seo 
        title={data.page.seo.title}
        description={data.page.seo.description}
        image={data.page.seo.image}
      />
    )
  }