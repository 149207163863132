import React from "react"
import Button from "../button"
import Row from "./row"
import * as styles from "./styles.module.css"

const ServiceOfferings = ({ headline, button, offerings }) => {
    return (
        <section>
            <div className="container-fluid pb-120 pb-60-tablet">
                <hr className="section-divider"></hr>
                <div className="row">
                    <div className="col-md-12">
                    <div className="flex space-between align-start">
                            <h3 className="m-0">{headline}</h3>
                            {button.map((data, index) =>
                                <Button
                                    key={index}
                                    type="purple"
                                    text={data.buttonText}
                                    url={data.buttonUrl}
                                    newTab={data.newTab}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.offerings}>
                    {offerings.map((card, index) =>
                        <Row key={index} data={card} index={index+1}/>
                    )}
                </div>
            </div>
        </section>
    )
}

export default ServiceOfferings