import React, { useState } from "react"
import * as styles from "./styles.module.css"

const ApproachCard = ({ data }) => {
    return (
        <div className={`col-lg-4 ${styles.card}`}>
            <div className={styles.top_row}>
                <p className={styles.headline}>{data.title}</p>
                <video width="112" height="112" autoplay="autoplay" muted="muted" loop="loop" playsInline={true}>
                    <source src={data.iconAnimation.url} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <p className={`l-body ${styles.description}`}>{data.description}</p>
        </div>
    )
}

export default ApproachCard