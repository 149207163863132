import React, { useState } from "react"
import * as styles from "./styles.module.css"

const DiagnosisCard = ({ data }) => {
    const [showDescription, setShowDescription] = useState(false)
    const handleClick = () => {
        setShowDescription(!showDescription)
    }
    return (
        <div className={`${styles.alternate_background_color} col-md-6`}>
            <button onClick={handleClick} className={`${styles.spacing} ${styles.card} ${showDescription ? styles.open : ""}`}>
                <div 
                    className={`${styles.learn_more} ${showDescription ? styles.rotate : ""}`}>
                    <span className={styles.visuallyHiddenMobile}>Learn More</span>
                </div>
                <div className={styles.text_container}>
                    <h2 className={`${showDescription ? styles.hide : ""}`}>{data.title}</h2>
                    <div className={`${showDescription ? styles.show : ""} ${styles.description}`} 
                    dangerouslySetInnerHTML={{ __html: data.description }}></div>
                </div>
            </button>
        </div>
    )
}

export default DiagnosisCard