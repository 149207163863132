// extracted by mini-css-extract-plugin
export var blackish = "#121117";
export var card = "styles-module--card--5546e";
export var colors = "\"../../../assets/css/_colors.css\"";
export var description = "styles-module--description--7b792";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var gtWalsheim = "'gt-walsheim'";
export var headline = "styles-module--headline--dc178";
export var lightBg = "#FFF8E8";
export var periwinkle = "#665CFF";
export var purple = "undefined";
export var top_row = "styles-module--top_row--2be74";
export var yellowBtn = "#FFE07B";