import React from "react"
import Button from "../button"
import ApproachCard from "./approachCard"
import * as styles from "./styles.module.css"

const OurApproach = ({ headline, button, cards }) => {
    return (
        <section>
            <div className="container-fluid pb-120 pb-60-tablet">
                <hr className="section-divider"></hr>
                <div className="row">
                    <div className="col-md-12">
                        <div className="flex space-between align-start">
                            <h3 className={styles.headline}>{headline}</h3>
                            {button.map((data, index) =>
                                <Button
                                    key={index}
                                    type="purple"
                                    text={data.buttonText}
                                    url={data.buttonUrl}
                                    newTab={data.newTab}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`row ${styles.cards_row}`}>
                    {cards.map((card, index) =>
                        <ApproachCard
                            key={index}
                            data={card}
                        />
                    )}
                </div>
            </div>
        </section>
    )
}

export default OurApproach