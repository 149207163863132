// extracted by mini-css-extract-plugin
export var alternate_background_color = "styles-module--alternate_background_color--fc5f2";
export var blackish = "#121117";
export var card = "styles-module--card--3837d";
export var colors = "\"../../../assets/css/_colors.css\"";
export var deepGrey = "#D0D0D0";
export var deepOrange = "#FF8A0F";
export var deepYellow = "#FFCC23";
export var description = "styles-module--description--63ac3";
export var fonts = "\"../../../assets/css/_fonts.css\"";
export var greyCard = "#DCDCDC";
export var gtWalsheim = "'gt-walsheim'";
export var hide = "styles-module--hide--a2288";
export var learn_more = "styles-module--learn_more--693f5";
export var lightBg = "#FFF8E8";
export var lightGreyBg = "#EAEAEA";
export var lightOrange = "#FFDCB7";
export var lightYellowBg = "#FFF0BD";
export var lightestPurple = "#E2DDFF";
export var mediumOrange = "#FFB96F";
export var open = "styles-module--open--76ce8";
export var periwinkle = "#665CFF";
export var purpleBtn = "#9D8CFF";
export var purpleCard = "#C0B6FF";
export var rotate = "styles-module--rotate--391f8";
export var show = "styles-module--show--08c7e";
export var spacing = "styles-module--spacing--f128f";
export var text_container = "styles-module--text_container--d1813";
export var visuallyHiddenMobile = "styles-module--visuallyHiddenMobile--43386";
export var yellowBtn = "#FFE07B";