import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./styles.module.css"

const Row = ({ index, data }) => {
    const [openListItem, setOpenListItem] = useState(-1)
    const handleClick = (index) => {
        if(openListItem === index) {
            setOpenListItem(-1)
        } else {
            setOpenListItem(index)
        }
    }
    return (
        <div className={`${styles.alternate_direction} row`}>
            <div className={`${styles.list_container} col-md-5 ${index % 2 === 0 ? "offset-md-1" : ""}`}>
                <h2 className={`m-0 ${styles.headline}`}>{data.title}</h2>
                {data.description ? <p className={styles.description}>{ data.description }</p> : "" }
                {data.expandableList.length > 0 ? <div className={styles.list}>
                    {data.expandableList.map((item, index) =>
                        <div 
                            onClick={() => {
                                handleClick(index)
                            }}
                            className={`${openListItem === index ? styles.open : "" } ${styles.list_item}`} 
                            key={index}>
                            <p className={styles.list_item_headline}>{item.headline}</p>
                            <div 
                                className={styles.list_description}
                                dangerouslySetInnerHTML={{ __html: item.body }}>
                            </div>
                        </div>
                    )}
                </div> : "" }
            </div>
            <div className={`${index % 2 === 0 ? "" : "offset-md-1"} col-md-6`}>
                <div className={styles.image_container}>
                    <div className={styles.border}></div>
                    <GatsbyImage 
                    objectPosition={`${data.image.focalPoint.x*100}% ${data.image.focalPoint.y*100}%`}
                    className={styles.gatsby_image} 
                    image={data.image.data} 
                    alt={data.image.alt} />
                </div>
            </div>
            <div className="col-md-12">
                <hr className={styles.dotted_hr} />
            </div>
        </div>
    )
}

export default Row