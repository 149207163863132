import React from "react"
import Button from "../button"
import DiagnosisCard from "./diagnosisCard"
import * as styles from "./styles.module.css"

const WhatWeTreat = ({ headline, button, treatments }) => {
    return (
        <section>
            <div className="container-fluid pb-80 pb-20-tablet">
                <hr className="section-divider"></hr>
                <div className="row">
                    <div className="col-md-12">
                    <div className="flex space-between align-start">
                            <h3 className={styles.headline}>{headline}</h3>
                            {button.map((data, index) =>
                                <Button
                                    key={index}
                                    type="purple"
                                    text={data.buttonText}
                                    url={data.buttonUrl}
                                    newTab={data.newTab}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="row mt-60 mt-40-tablet">
                    {treatments.map((block, index) =>
                        <DiagnosisCard key={index} data={block} />
                    )}
                </div>
            </div>
        </section>
    )
}

export default WhatWeTreat